import { Persistence, Service } from '../../framework'

import { RemittanceDetail }  from "../../entities"
import RemittanceService from './RemittanceService'
import EmploymentService from '../employer/EmploymentService'
import FinancialSummary from '../../entities/pension/FinancialSummary';
import RemittanceDetailSummary from '../../entities/pension/RemittanceDetailSummary';
import { RemittanceDetailBusiness } from '../../business';
import { setAPIError } from '../../hooks/useAPIError';
import { AdjustmentService } from '.';
import { Period } from '../../framework/utils';
import DetailListMapper from '../../mappers/DetailListMapper';
import { RefEvent } from '../../framework/infra';
import { chunk } from 'lodash'
import { ParticipationService } from '../membership';
import { EVENT_SOURCE } from '../../framework/infra/model/RefEvent';

/**
 * @extends {Service}
 */
class RemittanceDetailService extends Service {
    constructor() {
        super(RemittanceDetail, "RemittanceDetail", "remittance");
        this.persistences = {
            financialSummary: new Persistence(FinancialSummary),
            remittanceDetailSummary: new Persistence(RemittanceDetailSummary),
        };
    }

    /** @type {{financialSummary: Persistence; remittanceDetailSummary: Persistence;}} */
    persistences;

    updateRemittanceDetailsBulk(changes) {
        return this.updateByCallApi("UpdateRemittanceDetailsBulk", {}, changes);
    }

    updateYEComments(changes) {
        const allChangesKeys = Object.keys(changes);
        if(allChangesKeys.length > 50) {
            const batchSize = 50;
            const changesKeysBatches = chunk(allChangesKeys, batchSize); // [["", "", ...]]
            return Promise.all([...changesKeysBatches.map(changesKeysBatch => { // ["", "", ...]
                const changesBatch = {};
                for(const changesKey of changesKeysBatch){ // ""
                    changesBatch[changesKey] = changes[changesKey];
                }
                return this.updateByCallApi("UpdateRemittanceDetailsComments", {}, changesBatch);
            })]);
        } else return this.updateByCallApi("UpdateRemittanceDetailsComments", {}, changes);
    }

    /**
     * 
     * @param {Employment} employment 
     * @param {*} options 
     * @returns {Promise<RemittanceDetails>}
     */
    getEmploymentRemittancesDetails(employment, options) {
        return this.getBy(
            "GetRemittancesDetailsForEmployment",
            { employment: employment.keyValue },
            options, true
        ).then((details) => {
            return details.setYtps();
        });
    }

    /**
     * 
     * @param {string} employer 
     * @param {string} period 
     * @returns {Promise<RemittanceDetails>}
     */
    async getDetailsSummaryForRemittance(employer, period) {
        const action = 'GetDetailsSummaryForRemittance';
        return this.callApi(action, `${action}_${employer}${period}`, { employer, period, }, DetailListMapper);
    }

    getYearEndData(employer, employment, periods = []) {
        const action = 'GetYearEndData';
        const key = [employer, employment, periods.join(',')].join('_');
        let params = { periods };
        if (employer) params.employerId = employer;
        if (employment) params.employmentId = employment;

        return this.callApi(action, action + '_' + key, params, DetailListMapper);
    }

    /**
     * 
     * @param {Person} person 
     * @param {*} options 
     * @returns {Promise<RemittanceDetails>}
     */
    getMemberRemittanceDetails(person, options) {
        return this.getBy(
            "GetRemittanceDetailsForMember",
            { person: person.id },
            options, true
        ).then((details) => {
            return details;
        });
    }

    getFinancialSummaryForPeriods(start, end, employer) {
        return this.persistences.financialSummary.getList(
            "remittance_GetFinancialSummary",
            { employer: employer, startDate: start, endDate: end }
        );
    }

    /**
     * 
     * @param {*} key 
     * @param {*} options 
     * @returns {Promise<RemittanceDetails>}
     */
    getRemittanceDetails(key, options) {
        return this.getBy(
            "GetDetailsForRemittance",
            { remittance: key },
            options, true
        ).then((details) => {
            return this.initList(details);
        });
    }

    /**
     * 
     * @param {RemittanceDetail} detail 
     * @returns {RemittanceDetail}
     */
    async load(detail) {
        const id = detail.remittance.employer.keyValue +  "_" + detail.participation.keyValue;
        
        detail.remittance = await RemittanceService.get(detail.remittance.keyValue);
        detail.employment =  await EmploymentService.get(id);

        if (!detail.employment) console.warn("EMPLOYMENT NOT FOUND for this remittance detail", detail);

        return detail;
    }

    link(detail) {
        detail.earnings.assignEarningTypes(
            detail.remittance.employer.earningTypes
        );
        detail.ytpEarnings.assignEarningTypes(
            detail.remittance.employer.earningTypes
        );
        return detail;
    }

    /**
     * 
     * @param {RemittanceDetails} details 
     * @returns {RemittanceDetails}
     */
    initList(details) {
        const detsByRem = details.group("remittance.keyValue");
        Object.getOwnPropertyNames(detsByRem).forEach((remKey) => {
            detsByRem[remKey].first.remittance.details = detsByRem[remKey];
        });
        return details;
    }

    transferRemittanceDetails(startPeriod, oldKeys, newKeys) {
        this.invalidateCache();
        return this.persistence
            .callApi(this.moduleName + "_TransferDetails", {
                period: startPeriod,
                oldKeys: oldKeys,
                newKeys: newKeys,
            })
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }

    /**
     * Set the details and adjustments
     * 
     * Invoked by detail page to load all details
     * @param {Employer} employer 
     * @param {string} period 
     * @param {Remittance} remittance 
     * @returns {Promise<Remittance>}
     */
    async initDetails(employer, period, remittance) {
        try {
            const year = (new Period(period)).year;
            const adjustments = await AdjustmentService.getAdjustmentsForYear(employer.id, year);

            remittance.details = await this.getDetailsSummaryForRemittance(employer.id, period);
            remittance.details.appendMissingEarningTypes(remittance.employer.getActiveEarningTypes());
            remittance.adjustments = adjustments.filter((x) => x.period.isSame(remittance.period));

            remittance.details = remittance.details.mapList((det) => {
                    det.addAdjustmentsToYTP(remittance.adjustments.filter((x) => x.keysValues?.participation === det.keysValues?.participation && !x.period.isSame(det.period)));
                    det.initDetailAdjustment(adjustments);
                    if (det.isRelevant(true)) {
                        RemittanceDetailBusiness.validate(det);
                        return det;
                }}).getFiltered((element) => element !== undefined);

            return remittance;
        } catch (e) {
            setAPIError(e);
        }
    }

    /**
     * 
     * @param {Employment} employment 
     * @param {{excludePreviousTargetYearsContribAdjs: boolean | undefined;} | undefined} options - excludePreviousTargetYearsContribAdjs: exclude the contribution adjustments that have a target period not in the year of the remittance detail period year
     * @returns {RemittanceDetails}
     */
    loadDetailsWithAdjustmentsForEmployment = async (employment, options) => {
        const details = await this.getEmploymentRemittancesDetails(employment);
        const adjustments = await AdjustmentService.getAdjustmentsForEmployment(employment.employer.keyValue, employment.keysValues.participation,{ load: true, refresh: true });

        adjustments.all.forEach((adjustment) => {
            if (!details.find((detail) =>detail.period.isSame(adjustment.period))) details.push(adjustment.remDetail);
        });
        details.forEach((detail) =>detail.initDetailAdjustment(adjustments, options));
        return details;
    };

    /**
     * 
     * @param {*} details a list of details for the interval of period we want to calculate the expected deemed amounts for
     * @returns an object containing the expected earnings, hours and contribution amounts for ltd, maternity, self and totals
     *  for the input details list
     */
    calculateExpectedDeemed(details) {
        const recalculatedDetails = details.map((det) => {
            det.employment.events.sortEvents();
            RemittanceDetailBusiness.calculateContribs(det);
            details.setYtps();
            return det;
        });

        return {
            calculatedDetails: recalculatedDetails,
            expected: recalculatedDetails.reduce(
            (totals, detail) => {
                return {
                    total: {
                        earnings: totals.total.earnings + detail.earnings.deemed,
                        hours: totals.total.hours + detail.earnings.deemedHours,
                        contributions: totals.total.contributions + detail.contributions.deemed,
                    },
                    ltd: {
                        earnings: totals.ltd.earnings + detail.earnings.ltd,
                        hours: totals.ltd.hours + detail.earnings.ltdHours,
                        contributions: totals.ltd.contributions + detail.contributions.ltd,
                    },
                    mat: {
                        earnings: totals.mat.earnings + detail.earnings.mat,
                        hours: totals.mat.hours + detail.earnings.matHours,
                        contributions: totals.mat.contributions + detail.contributions.mat,
                    },
                    slf: {
                        earnings: totals.slf.earnings + detail.earnings.slf,
                        hours: totals.slf.hours + detail.earnings.slfHours,
                        contributions: totals.slf.contributions + detail.contributions.slf,
                    },
                };
            },
            {
                total: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,
                },
                ltd: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                },
                mat: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                },
                slf: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                }
            }
        )}
    }

    /**
     * Add a `nrolFday` participation event if needed
     * 
     * If the remittance detail's employment's participation is eligible period (participation status `ELI`),
     * and the remittance detail's earnings are not empty or the uploaded earnings are not empty,
     * and the participation doesn't already have a `nrolFday` event
     * 
     * Add first day event with guessed date (remittance's period start)
     * 
     * see APP-1141 (https://sysem.atlassian.net/browse/APP-1141)
     * @param {{earnings?: {isEmpty?: boolean | undefined;} | undefined; period: Period; employment: Employment | null | undefined;}} detail 
     * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings 
     * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options Options: - option to do a dry-run (not persist the changes in the db). - remittance ref (for the RTW event) - flag to set the event source
     * @returns {{addedRtwEvent: boolean; addedNrolfdayEvent: boolean;}}
     */
    async handleEligibleParticipation(detail, uploadedEarnings, options) {
        const result = {addedRtwEvent: false, addedNrolfdayEvent: false};
        const hasEarnings = (!detail.earnings?.isEmpty || !uploadedEarnings?.earnings?.isEmpty);
        if(hasEarnings) {
            const hasFirstDayEvent = detail?.employment?.participation?.events?.find?.(e => e.config.isFirstDayEvent);
            if (detail.employment?.participation?.status?.isEligiblePeriod() && !hasFirstDayEvent) {
                const nrolFdayEvent = { code: 'nrolFday', ets: detail.period.timestampAtPeriodStart, guessed: true, source: options?.eventsSource ?? EVENT_SOURCE.FILE.key }
                // Update the participation
                // Need to add the event here for the checksBeforeUpdate
                detail.employment.participation.addEvent(nrolFdayEvent, {openRemittance: options?.openRemittance});
                const beforeUpdateChecks = await ParticipationService.checksBeforeUpdate(detail.employment.participation)
                if(options?.commit === false) {
                    // rollback the changes, remove the event
                    detail.employment.participation.deleteEvent(nrolFdayEvent);
                    result.addedNrolfdayEvent = true;
                    result.addedRtwEvent = Boolean(beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.shouldAddRtwEvent);
                } else {
                    // Will check if we need to add a RTW event to the employments of the participation, and add it if needed
                    await ParticipationService.updateParticipation(detail.employment.participation, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
                    const afterUpdateChecks = await ParticipationService.checksAfterUpdate(detail.employment.participation);
                    result.addedNrolfdayEvent = true;
                    result.addedRtwEvent = beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave === false && 
                        afterUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave === true;
                }
            }
        }
        return result;
    }
}
const instance = new RemittanceDetailService()
export default instance

